import { DateRange } from "@mui/lab";
import { add, addDays } from "date-fns";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import {
  ConditionsSearchType,
  IdType,
  sanatoriumsApi,
  sanatoriumsFilterServiceApi,
} from "../../api";
import { fetchAll, fetchById } from "../../utils/fetchData";
import { getPaginationKeys } from "../../utils/useQueryKeys";
import { formatDate, today } from "../../utils/workingWithDates";
import { countriesState } from "../index";

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateIntervalForSanatoriums = atom<DateRange<Date>>([today, addDays(today, 13)]);

// ------------------------------ ТИП СОРТИРОВКИ

export const sortingTypeState = atom<"lowPrice" | "highPrice">("lowPrice");

// ------------------------------ ФИЛЬТРЫ

export const sanatoriumsAbsoluteInitialValues = {
  cityRegion: [],

  sanatoriums: [],
  isRehab: "false",
  profiles: [],
  rating: 0,

  startDate: today,
  endDate: addDays(today, 13),
};

export const sanatoriumsFiltersState = atom<FiltersType>(sanatoriumsAbsoluteInitialValues);

type FiltersType = {
  cityRegion?: string[];

  sanatoriums?: string[];
  isRehab: string;
  profiles: string[];
  rating?: number;

  startDate: Date | null;
  endDate: Date | null;
};

const getFilterDTO = (props: FiltersType, countriesOptions: IdType[]) => {
  const stringToObjArray = (array: string[]) => array.map((id) => ({ id }));
  const isCountry = (idIn: string) => countriesOptions.some(({ id }) => id === idIn);

  const { sanatoriums, cityRegion, isRehab, profiles, rating, startDate, endDate } = props;

  const regions: string[] = [];
  const countries: string[] = [];

  cityRegion?.forEach((cityOrRegion) => {
    if (isCountry(cityOrRegion)) countries.push(cityOrRegion);
    else regions.push(cityOrRegion);
  });

  return {
    ...(sanatoriums?.length ? { sanatoriums: stringToObjArray(sanatoriums) } : {}),
    ...(profiles.length ? { profiles: stringToObjArray(profiles) } : {}),
    ...(regions.length ? { regions: stringToObjArray(regions) } : {}),

    ...(countries.length ? { countries: stringToObjArray(countries) } : {}),
    ...(rating ? { rating: rating } : {}),

    isRehab,
    since: formatDate({ date: startDate ? startDate : today, type: "forBackend" }),
    until: formatDate({ date: endDate ? endDate : add(today, { weeks: 2 }), type: "forBackend" }),
  };
};

// ------------------------------ ДАННЫЕ

const sanatoriumListAtom = atomWithQuery((get) => {
  const { page, size } = getPaginationKeys(get);
  const filters = get(sanatoriumsFiltersState);
  const order = get(sortingTypeState);
  const countriesOptions = get(countriesState);

  const args = {
    filters,
    order,
    size,
    page,
    countriesOptions,
  };

  return sanatoriumListQuery(args);
});

export const sanatoriumListState = loadable(sanatoriumListAtom);

const sanatoriumListQuery = (props: PropsType) => {
  const { filters, order, countriesOptions } = props;
  const { size, page } = props;

  const sanatoriumFilterDto = {
    ...getFilterDTO(filters, countriesOptions),
    page: {
      priceSort: order === "lowPrice" ? "asc" : "desc",
      itemsPerPage: size,
      page,
    },
  };
  const args = { sanatoriumFilterDto };

  return {
    queryKey: [page, size, ...Object.values(filters), "sanatoriumListState"],
    queryFn: () => fetchAll(() => sanatoriumsFilterServiceApi.getAll(args)),
  };
};

// ------------------------------ ВСЕ АКТИВНЫЕ САНАТОРИИ

const allActiveSanatoriumsQuery = atomWithQuery(() => {
  const filter: ConditionsSearchType = [{ property: "active", operator: "=", value: "true" }];

  return {
    queryKey: ["allActiveSanatoriumsState"],
    queryFn: () => fetchById(() => sanatoriumsApi().getAll({ filter, startIndex: 0, size: 0 })),
  };
});

export const allActiveSanatoriumsState = loadable(allActiveSanatoriumsQuery);

type PropsType = {
  size: number;
  page: number;
  countriesOptions: IdType[];
} & FilterQueryType;

type FilterQueryType = {
  order: string;
  filters: FiltersType;
};
