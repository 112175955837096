import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { ConditionsSearchType, familyMembersApi, FamilyMemberType } from "../../api";
import { OnDeleteReturnType } from "../../uiKit";
import { fetchItems } from "../../utils/fetchData";
import { applicationState, employeeInfoAtom, userPersonnelNumberState } from "../index";

export const familyMembersModalDataState = atom<FamilyMemberType | undefined>(undefined);
export const familyMembersModalDeleteState = atom<DeleteModalType | undefined>(undefined);

export const openDocumentListsState = atomWithImmer<string[]>([]);

const familyMemberQuery = (employeeId: string, filter: ConditionsSearchType, key: string) => ({
  queryKey: [employeeId, key],
  queryFn: () => fetchItems(() => familyMembersApi().getAll({ filter })),
});

// ------------------------------ ЧЛЕНЫ СЕМЬИ (ПОЛЬЗОВАТЕЛЯ)

const userFamilyMembersAtom = atomWithQuery((get) => {
  const userPersonnelNumber = get(userPersonnelNumberState);

  const filter = [
    { property: "employee.personnelNumber", operator: "=", value: userPersonnelNumber },
  ];

  return familyMemberQuery(userPersonnelNumber, filter, "familyMembersState");
});

export const userFamilyMembersState = loadable(userFamilyMembersAtom);

type DeleteModalType = {
  questionText: string;
  successText: string;
  onDelete: () => OnDeleteReturnType<unknown>;
};

// ------------------------------ ЧЛЕНЫ СЕМЬИ (РАБОТНИКА) (ДЛЯ ФОРМЫ ЗАЯВКИ)

const employeeFamilyMembersAtom = atomWithQuery((get) => {
  const applicationLoadable = get(applicationState);
  const employeeInfo = get(employeeInfoAtom);

  const application =
    applicationLoadable.state === "hasData" ? applicationLoadable.data : undefined;
  const personnelNumber =
    application?.employee.personnelNumber ?? employeeInfo?.personnelNumber ?? "";

  const filter = [{ property: "employee.personnelNumber", operator: "=", value: personnelNumber }];

  return familyMemberQuery(personnelNumber, filter, "employeeFamilyMembersState");
});

export const employeeFamilyMembersState = loadable(employeeFamilyMembersAtom);
