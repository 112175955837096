import * as muiElements from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material";
import React, { FC, HTMLAttributes, ReactNode, SyntheticEvent } from "react";
import { colors, Error } from "../../styles";
import { OptionsType, OptionType } from "../../utils/getOptions";
import { useRhfField, useRhfFormInField } from "../../utils/useRHF";
import { CrossInCircle, TriangleDown } from "../Icons";
import * as elements from "./SelectAutocompleteInput.styles";

export const SelectAutocompleteInput: FC<PropsType> = (props) => {
  const { name, label, placeholder = "Выберите из списка", iconStart } = props;
  const { size = "", disabled = false, color = colors.grayscaleInput } = props;
  const { resetEnabled, title, description } = props;

  let { options } = props;

  options = [
    { id: "", name: "" },
    ...options,
    ...(options.length ? [] : [{ id: "empty", name: "Список пуст" }]),
  ];

  const { Container, Item, MenuItemWrapper, PopperComponent, PaperProps } = elements;
  const { ResetButton, Title, Description } = elements;
  const { MenuItem, TextField, InputAdornment } = muiElements;

  const { trigger, submitCount } = useRhfFormInField();
  const { value = "", setValue, error, touched, onBlur } = useRhfField<string | number>(name);

  const hasError = (!!touched || !!submitCount) && !!error;

  const selectedOption = [{ id: "", name: "" }, ...options].find(({ id }) => id === value);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      name={name}
      {...params}
      label={label}
      placeholder={placeholder}
      InputProps={
        iconStart
          ? {
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{iconStart}</InputAdornment>,
            }
          : params.InputProps
      }
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) =>
    option.id === "" ? null : (
      <MenuItem {...(option.id === "empty" ? {} : { ...props, key: option.id })}>
        <MenuItemWrapper>{option.name}</MenuItemWrapper>
      </MenuItem>
    );

  const handleChange = (
    _evt: SyntheticEvent<Element, Event>,
    arg: string | OptionType | (string | OptionType)[] | null
  ) => {
    if (!arg || typeof arg === "string" || Array.isArray(arg) || !("id" in arg)) return;

    setValue(arg.id);
    trigger(name);
  };

  return (
    <Container disabled={disabled}>
      {title ? <Title>{title}</Title> : null}

      <Item
        value={selectedOption}
        options={options}
        getOptionLabel={(option) => option?.name as string}
        onChange={handleChange}
        onBlur={onBlur}
        renderInput={renderInput}
        renderOption={renderOption}
        popupIcon={<TriangleDown />}
        closeText=""
        openText=""
        haserror={+hasError}
        PopperComponent={PopperComponent}
        componentsProps={{ paper: PaperProps(size) }}
        noOptionsText="Нет совпадений"
        isOptionEqualToValue={(option) => option?.id === value}
        haslabel={+!!label}
        disabled={disabled}
        color={color}
      />

      {resetEnabled && value ? (
        <ResetButton onClick={() => setValue("")}>
          <CrossInCircle />
        </ResetButton>
      ) : null}

      {hasError ? (
        <Error bottom={-14}>{error}</Error>
      ) : (
        description &&
        (typeof description === "string" ? <Description>{description}</Description> : description)
      )}
    </Container>
  );
};

type PropsType = {
  name: string;
  options: OptionsType;
  title?: string;
  label?: string;
  placeholder?: string;
  size?: "small";
  disabled?: boolean;
  iconStart?: ReactNode;
  color?: string;
  resetEnabled?: boolean;
  description?: string | JSX.Element;
};
