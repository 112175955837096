import React, { FC } from "react";
import { useRhfField } from "../../utils/useRHF";
import { PropsSliderNumberType, SliderNumber } from "./SliderNumber";

export const SliderNumberInput: FC<PropsType> = (props) => {
  const { name } = props;

  const { setValue, onBlur: handleBlur } = useRhfField<number>(name);

  return <SliderNumber {...props} onBlur={handleBlur} onChange={setValue} editMode />;
};

type PropsType = PropsSliderNumberType & {
  name: string;
};
