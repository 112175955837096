import React, { MouseEvent } from "react";
import { EntitiesType } from "../../../api";
import { GearButton, SelectAllButton } from "../../Buttons";
import { Check } from "../../Check";
import { PopoverHover } from "../../PopoverHover";
import { PopoverModal } from "../../PopoverModal";
import { TitleList } from "../Table.styles";
import { GridsType, TitlesType, useTable } from "../useTable";
import { Container, TableHeadItem } from "./TableHeadRow.styles";

/**
 *
 * ------------------------------------------------------------------------------------------
 * КОМПОНЕНТ СТРОКИ ЗАГОЛОВКОВ ТАБЛИЦЫ
 *
 * -
 *
 * @param props - параметры
 * @param props.titlesInit - массив объектов заголовков столбцов (обязательный)
 * @param props.titlesInit[i].id - идентификатор столбца (number) (обязательный)
 * @param props.titlesInit[i].title - заголовок (string) (обязательный)
 * @param props.titlesInit[i].name - наименование столбца (string) (обязательный)
 * @param props.titlesInit[i].icon - иконка заголовка (ReactNode) (не обязательный)
 * @param props.titlesInit[i].iconPosition - положение иконки ("left" | "right") (не обязательный)
 * @param props.gridsInit - массив ширин столбцов (обязательный)
 * @param props.gridsInit[i].titleId - идентификатор столбца, null для случаев первого столбца с чекбоксами и последнего с меню (number | null) (обязательный)
 * @param props.gridsInit[i].size - ширина столбца во фракциях или пикселях, в случае добавления столбцов для чекбокс и меню их ширина - 56px (string) (обязательный)
 * @param props.ids - параметр, указывающий на необходимость столбца выбора строк (never | string[]) (обязательный)
 * @param props.needCheck - параметр, указывающий на необходимость столбца выбора строк - если передан, то массив props.ids так же обязателен (never | boolean) (обязательный)
 * @param props.needMenu - параметр, указывающий на необходимость столбца меню (boolean) (обязательный)
 * @param props.setUserSettings - установщик пользовательских настроек отображения столбцов  ((arg: number[]) => void) (обязательный)
 *
 */

export const TableHeadRow = <DATUM extends { id?: string }>(props: PropsType<DATUM>) => {
  const { ids, data, titlesInit, gridsInit, setUserSettings } = props;
  const { needCheck = false, needMenu = false } = props;

  const tableMethods = useTable();
  const { getTitles, getGrids, selectAllRows, selectColumns, nonEditableId } = tableMethods;
  const { anchorHeader, openHint, closeHint, hintText, isColumnSelected } = tableMethods;
  const { columnSettingsButton, openColumnSettings, closeColumnSettings } = tableMethods;

  const allIdsNonEditable = !data.some((datum) => (datum.id ? !nonEditableId(datum.id) : false));

  return (
    <>
      <Container needCheck={needCheck} needMenu={needMenu} grid={getGrids(gridsInit)}>
        {needCheck && (
          <TableHeadItem
            className="check"
            aria-owns={anchorHeader && "hint"}
            aria-haspopup="true"
            onMouseEnter={(evt) => openHint(evt, allIdsNonEditable)}
            onMouseLeave={closeHint}
          >
            <SelectAllButton
              onClick={(evt) => {
                evt.preventDefault();
                !!ids?.length && selectAllRows(ids);
              }}
              disabled={allIdsNonEditable}
            />
          </TableHeadItem>
        )}

        {getTitles(titlesInit).map(({ id, title, icon, iconPosition }) => (
          <TableHeadItem
            key={id}
            aria-owns={anchorHeader && "hint"}
            aria-haspopup="true"
            onMouseEnter={(evt) => openHint(evt, allIdsNonEditable)}
            onMouseLeave={closeHint}
          >
            {iconPosition === "left" && icon}
            <p>{title}</p>
            {iconPosition === "right" && icon}
          </TableHeadItem>
        ))}

        {needMenu && <TableHeadItem className="menu" />}

        <GearButton
          onClick={(evt: MouseEvent<HTMLElement>) => {
            evt.preventDefault();
            openColumnSettings(evt);
          }}
          menuModalIsOpen={!!columnSettingsButton}
          needMenu={needMenu}
          needSelectingColumns={true}
          hintText="Настройка таблицы"
        />
      </Container>

      <PopoverHover
        id="hint"
        isOpen={!!anchorHeader}
        element={anchorHeader}
        onClose={closeHint}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: 12, horizontal: -12 }}
      >
        {hintText}
      </PopoverHover>

      <PopoverModal
        id="optionRowsModal"
        title="Колонки таблицы"
        isOpen={!!columnSettingsButton}
        element={columnSettingsButton}
        onClose={closeColumnSettings}
      >
        <TitleList>
          {titlesInit.map(({ id, title }) => (
            <div key={id} onClick={() => selectColumns(id, setUserSettings)}>
              <Check checked={isColumnSelected(id)} />
              <p>{title}</p>
            </div>
          ))}
        </TitleList>
      </PopoverModal>
    </>
  );
};

type PropsType<DATUM> = BaseType<DATUM> &
  ({ needCheck?: never; ids?: never } | { needCheck: boolean; ids: string[] });

type BaseType<DATUM> = {
  data: EntitiesType<DATUM>;
  titlesInit: TitlesType;
  gridsInit: GridsType;
  needMenu?: boolean;
  setUserSettings?: (arg: number[]) => void;
};
