import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { atomWithQuery } from "jotai/query";
import { atomWithStorage } from "jotai/utils";
import { DownloadStatusType, sanatoriumsApi } from "../api";
import { fetchItems } from "../utils/fetchData";

// ------------------------------ НУЛЕВОЙ АТОМ

export const nullAtom = atom(null);

// ------------------------------ ИСТОРИЯ ПЕРЕХОДОВ ПО СТРАНИЦАМ

export const historyState = atomWithStorage<string[]>("history", []);

// ------------------------------ LOADER'Ы ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ/УДАЛЕНИЯ СТРОКИ ТАБЛИЦЫ

export const deletionStatusState = atom<boolean>(false);
export const createOrUpdateStatusState = atom<boolean>(false);
export const lifecycleStatusState = atom<boolean>(false);

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ФАЙЛА

export const statusFileInputState = atom<DownloadStatusType>("empty");

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ФОТОГРАФИЙ

export const statusPhotosInputState = atom<DownloadStatusType>("empty");

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ИКОНКИ

export const statusIconInputState = atom<DownloadStatusType>("empty");

// ------------------------------ ВЫБРАННЫЕ СТРОКИ

export const selectedRowsState = atomWithImmer<string[]>([]);

// ------------------------------ ВЫБРАННЫЕ СТОЛБЦЫ

export const selectedColumnsState = atomWithImmer<number[]>([]);

// ------------------------------ ВЫБРАННЫЕ ОРГАНИЗАЦИИ

export const selectedOrganizationsAtom = atomWithImmer<string[]>([]);

// ------------------------------ ПОИСКОВЫЙ ЗАПРОС ПО СТРАНИЦАМ

export const searchQueryState = atom<string>("");

// ------------------------------ ПОИСКОВЫЙ ЗАПРОС ПО МОДАЛЬНЫМ ОКНАМ

export const modalSearchQueryState = atom<string>("");

// ------------------------------ СОСТОЯНИЕ ЛЕВОГО МЕНЮ

export const menuIsExpandState = atom<boolean>(false);

// ------------------------------ ТИП ПЕРЕКЛЮЧАТЕЛЯ ДАТ

export const dateSwitcherTypeState = atom<DateSwitcherTypeType | "">("");

// ------------------------------ СОСТОЯНИЕ ПЕРЕКЛЮЧАТЕЛЯ ПЕРИОДОВ ДАТ

export const periodSwitcherTypeState = atom<DateSwitcherTypeType>("year");

// ------------------------------ ПАГИНАЦИЯ - НОМЕР СТРАНИЦЫ
export const paginationState = atom<PaginationStateType>({ page: 0, quantity: 10 });

// ------------------------------ ПАГИНАЦИЯ - НОМЕР СТРАНИЦЫ В МОДАЛЬНОМ ОКНЕ

export const modalPaginationState = atom<PaginationStateType>({ page: 0, quantity: 50 });

// ------------------------------ ФОРМЫ - СОСТОЯНИЕ

export const addFormState = atom<boolean>(false);
export const filtersFormState = atom<boolean>(false);

// ------------------------------ СОСТОЯНИЕ СТРОКИ ПОИСКА

export const visibleSearchState = atom<boolean>(false);

// ------------------------------ ПРЕДПРОСМОТР РЕЕСТРА

export const registryPreviewState = atom<RegistryPreviewType>(false);

export type RegistryPreviewType = "application" | "travelPackage" | false;

// ------------------------------ ВСЕ САНАТОРИИ ДЛЯ OPTIONS

export const allSanatoriumsState = atomWithQuery(() => ({
  queryKey: "allSanatoriumsState",
  queryFn: () => fetchItems(() => sanatoriumsApi().getAll()),
}));

// ------------------------------ ТИПЫ

export type DateSwitcherTypeType = "day" | "month" | "quarter" | "year" | "period";
export type PaginationStateType = { page: number; quantity: number };
