import saveAs from "file-saver";
import React, { FC, useState } from "react";
import { DocumentsForFormType, FilePropsType } from "../../api";
import { FileLink } from "../../components";
import { fileSizeConversion } from "../../utils/fileSizeConversion";
import { OptionsType } from "../../utils/getOptions";
import { useRhfField, useRhfFields } from "../../utils/useRHF";
import { EditButton, OkButton, TrashButton } from "../Buttons";
import { Book } from "../Icons";
import TextInput from "../Inputs/TextInputRHF";
import { SelectAutocompleteInput } from "../SelectAutocompleteInput";
import * as elements from "./DocumentsInput.styles";
import { Buttons } from "./DocumentsInput.styles";

export const DocumentItem: FC<PropsType> = (props) => {
  const { i, id, fieldName, file, name, comment, options, optionsLabel, forEditForm } = props;

  const [editing, setEditing] = useState<number>();

  const { Container, Text, Title, Description, FileBlock, LoadBar } = elements;

  const { removeValue } = useRhfFields<DocumentsForFormType | []>(fieldName);
  const { setValue } = useRhfField<File>(`${fieldName}.${i}.file`);

  const parameters = comment ? JSON.parse(comment) : "";
  const accept = parameters.accept;
  const size = parameters.size;

  return (
    <FileBlock key={i}>
      <Container status="wasFile" little>
        <LoadBar status="loaded">
          <Book />
          {comment && accept ? accept : ""}
        </LoadBar>

        {editing === i ? (
          <TextInput name={`${fieldName}.${i}.name`} />
        ) : (
          <Text>
            {id !== "" && typeof file === "string" ? (
              <Title status="wasFile">
                <FileLink file={file} name={`${name}${accept}`} />
              </Title>
            ) : id === "" && file && typeof file !== "string" ? (
              <Title
                status="wasFile"
                onClick={() => (comment && accept ? saveAs(file, `${name}${accept}`) : undefined)}
                needDownload
              >
                {name}
              </Title>
            ) : null}

            <Description status="wasFile">
              {comment && size ? `${fileSizeConversion(size)}` : ""}
            </Description>
          </Text>
        )}

        <Buttons>
          {forEditForm || editing === i ? null : <TrashButton onClick={() => removeValue(i)} />}

          {editing === i ? (
            <OkButton
              onClick={() => {
                setEditing(undefined);
                file instanceof File &&
                  setValue(new File([file], `${name}.${file.name.split(".").pop()}`));
              }}
            />
          ) : (
            <EditButton onClick={() => setEditing(i)} />
          )}
        </Buttons>
      </Container>

      {options && (
        <SelectAutocompleteInput
          name={`${fieldName}.${i}.type`}
          options={options}
          label={optionsLabel ?? ""}
        />
      )}
    </FileBlock>
  );
};

type PropsType = {
  i: number;
  id: string;
  fieldName: string;
  file: FilePropsType;
  name: string;
  comment: string;
  options?: OptionsType;
  optionsLabel?: string;
  forEditForm: boolean;
};
