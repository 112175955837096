import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import {
  absoluteInitialVacationers,
  otherInitialVacationers,
  selectedRoomsState,
} from "../../../../../../atoms";
import { FormButtons } from "../../../../../../uiKit";
import { useRhfOutsideOfForm } from "../../../../../../utils/useRHF";
import { Form, Modal } from "./VacationersModal.styles";
import { useForms } from "./hooks/useForms";

export const VacationersModal: FC<PropsType> = (props) => {
  const { onClose } = props;

  const { formName, formFields, initialValue, onSubmit, validationSchema } = useForms();

  const selectedRooms = useAtomValue(selectedRoomsState);

  const { rhfMethods, setValues, handleSubmit } = useRhfOutsideOfForm<typeof initialValue>({
    defaultValues: initialValue,
    resolver: validationSchema,
  });

  return (
    <Modal>
      <FormProvider {...rhfMethods}>
        <Form
          id={formName}
          onSubmit={handleSubmit((values) => {
            onSubmit(values);
            onClose();
          })}
        >
          {formFields}

          <FormButtons
            formName={formName}
            onClear={() =>
              setValues(selectedRooms.length ? otherInitialVacationers : absoluteInitialVacationers)
            }
            saveOrAdd="clear"
          />
        </Form>
      </FormProvider>
    </Modal>
  );
};

type PropsType = {
  onClose: () => void;
};
