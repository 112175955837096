import { useAtom } from "jotai";
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { BigBackendDateType, DocumentForFormType, DocumentsForFormType } from "../../api";
import { statusFileInputState } from "../../atoms";
import * as messages from "../../constants/forValidationSchemes";
import { fileSizeConversion } from "../../utils/fileSizeConversion";
import { useRhfField, useRhfFields, useRhfFormInField } from "../../utils/useRHF";
import { formatDate, now } from "../../utils/workingWithDates";

const { falseAcceptFile, falseSizeFile, requiredSingleFile } = messages;

export const useDocumentsInput = (props: PropsType) => {
  const { accept, maxSize, name, oneDocument } = props;

  // ------------------------ АТОМЫ

  const [status, setStatus] = useAtom(statusFileInputState);

  // ------------------------ СТЕЙТЫ

  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [timerSuccess, setTimerSuccess] = useState<ReturnType<typeof setTimeout>>();
  const [timerIdLoaded, setTimerIdLoaded] = useState<ReturnType<typeof setTimeout>>();
  const [timerProgress, setTimerProgress] = useState<ReturnType<typeof setInterval>>();

  // ------------------------ ХУКИ

  const ref = useRef<HTMLInputElement>(null);

  const { trigger, isSubmitting } = useRhfFormInField();
  const { error: documentsError } = useRhfField<DocumentsForFormType | []>(name);
  const { values: documents, addValue, removeValue } = useRhfFields<DocumentForFormType>(name);

  // ------------------------ ЭФФЕКТЫ

  useEffect(() => {
    if (status === "wasFile") trigger(name);
  }, [status]);

  useEffect(() => {
    if (documentsError && !error) {
      setError(
        !oneDocument ? "Один из файлов не соответствует размеру или типу" : documentsError ?? ""
      );
      setStatus("error");
    }
  }, [isSubmitting]);

  useEffect(() => {
    documents?.length && setStatus("wasFile");
    return () => setStatus("empty");
  }, []);

  // ------------------------ ФУНКЦИИ-ПРЕОБРАЗОВАТЕЛИ

  const fileNames = (element?: RefObject<HTMLInputElement>) => {
    const fileArr = element?.current?.files && Array.from(element.current.files);
    const fileNamesArr = fileArr && fileArr.length ? fileArr.map(({ name }) => name) : [];
    return fileNamesArr.join(", ");
  };

  const filesSize = (element?: RefObject<HTMLInputElement>) => {
    const fileArr = element?.current?.files && Array.from(element.current.files);
    return fileArr && fileArr.length
      ? fileSizeConversion(fileArr.reduce((acc, values) => acc + values.size, 0))
      : 0;
  };

  // ------------------------ ФУНКЦИИ-ВАЛИДАТОРЫ

  const validFileType = (file: File) => {
    if (!accept || !file) {
      return true;
    }

    if (!accept.some((item) => item === file?.name.slice(file?.name.lastIndexOf(".")))) {
      setError(falseAcceptFile(accept));
      return false;
    }

    return true;
  };

  const validFileSize = (file: File) => {
    if (!maxSize || !file || file.size <= maxSize) {
      return true;
    }

    setError(falseSizeFile(maxSize));
    return false;
  };

  const validMultiple = (filesArr: File[]) => {
    if (!oneDocument) {
      return true;
    }

    if (filesArr.length > 1) {
      setError(requiredSingleFile);
      return false;
    }

    return true;
  };

  // ------------------------ ОБРАБОТЧИКИ

  const addFile = (files: FileList) => {
    const filesArr = Array.from(files);

    if (!filesArr.length) return;

    const filesMultipleNotValid = !validMultiple(filesArr);

    if (filesMultipleNotValid) {
      setStatus("error");
      return;
    }

    const fileTypeNotValid =
      files instanceof File
        ? !validFileType(files)
        : !!filesArr.find((file) => !validFileType(file));

    if (fileTypeNotValid) {
      setStatus("error");
      return;
    }

    const fileSizeNotValid =
      files instanceof File
        ? !validFileSize(files)
        : !!filesArr.find((file) => !validFileSize(file));

    if (fileSizeNotValid) {
      setStatus("error");
      return;
    }

    const filesObj = filesArr.map((file) => ({
      id: "",
      file: file,
      name: file.name?.slice(0, file.name.lastIndexOf(".")),
      type: "",
      comment: JSON.stringify({
        accept: file.name?.slice(file.name.lastIndexOf(".")),
        size: file.size,
      }),
      createdDate: `${formatDate({
        date: now,
        type: "forBackend",
      })}T00:00:00.000` as BigBackendDateType,
    }));

    setStatus("progress");

    const intervalTimer = setInterval(() => {
      setProgress((prevState) => (prevState >= 100 ? 0 : prevState + 20));
    }, 300);

    setTimerProgress(intervalTimer);

    setTimerSuccess(setTimeout(() => setStatus("success"), 1500));
    setTimerIdLoaded(
      setTimeout(() => {
        addValue(filesObj);
        setStatus("loaded");
        if (ref.current) ref.current.value = "";
      }, 2500)
    );

    setTimeout(() => {
      setProgress(0);
      clearInterval(intervalTimer as ReturnType<typeof setInterval>);
    }, 1500);
  };

  const handleRemove = (ref: RefObject<HTMLInputElement>) => {
    setProgress(0);

    clearTimeout(timerSuccess as ReturnType<typeof setTimeout>);
    clearTimeout(timerIdLoaded as ReturnType<typeof setTimeout>);
    clearInterval(timerProgress as ReturnType<typeof setInterval>);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const input = ref.current!;
    input.value = "";
    removeValue();
    setStatus("empty");
    setError("");
  };

  const onChange = (evt: ChangeEvent) => {
    const files = (evt.target as HTMLInputElement).files;

    if (!files) return;

    addFile(files);
  };

  return {
    ref,
    documents,

    progress,
    status,
    error,

    fileNames,
    filesSize,

    addFile,
    handleRemove,
    onChange,
  };
};

type PropsType = {
  name: string;
  accept: string[];
  maxSize: number;
  oneDocument: boolean;
};
