import React, { FC } from "react";
import { DocumentsForFormType } from "../../api";
import { FormSubTitle } from "../../styles";
import { OptionsType } from "../../utils/getOptions";
import { DocumentItem } from "./DocumentItem";
import { FileBlocks } from "./DocumentsInput.styles";

export const DocumentList: FC<ListPropsType> = (props) => {
  const { documents, options, optionsLabel, forEditForm, name: fieldName } = props;

  const args = { options, optionsLabel, forEditForm, fieldName };

  return (
    <>
      {forEditForm ? null : <FormSubTitle>Загруженные документы</FormSubTitle>}

      <FileBlocks>
        {documents.map(({ id, file, name, comment }, i) => (
          <DocumentItem key={i} {...args} i={i} id={id} file={file} name={name} comment={comment} />
        ))}
      </FileBlocks>
    </>
  );
};

export type ListPropsType = {
  name: string;
  documents: DocumentsForFormType | [];
  forEditForm: boolean;
  options?: OptionsType;
  optionsLabel?: string;
};
