import React, { FC, MouseEvent, ReactNode, useState } from "react";
import { useRhfField } from "../../utils/useRHF";
import { Check, CheckboxVariantType } from "../Check";
import { PopoverHover } from "../PopoverHover";
import { Container, Info, Label } from "./CheckInput.styles";

export const CheckInput: FC<PropsType> = (props) => {
  const { name, label = "", variant, hint } = props;
  const { needMargin = true, disabled = false, labelType = "default" } = props;

  const { value, setValue } = useRhfField<boolean>(name);

  // ------------------------------ < ПОДСКАЗКА

  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorElement(currentTarget);

  const closeHint = () => setAnchorElement(undefined);

  // ------------------------------ ПОДСКАЗКА >

  return (
    <Container
      disabled={disabled}
      needMargin={needMargin}
      labelType={labelType}
      onClick={() => !disabled && setValue(!value)}
    >
      <Check checked={value} variant={variant} disabled={disabled} hintText="" />
      <Label disabled={disabled}>{label}</Label>

      {!!hint && (
        <Info
          aria-owns={anchorElement && "hint"}
          aria-haspopup="true"
          onMouseEnter={openHint}
          onMouseLeave={closeHint}
        >
          i
        </Info>
      )}

      <PopoverHover
        id="hint"
        isOpen={!!anchorElement}
        element={anchorElement}
        onClose={closeHint}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: -6, horizontal: "center" }}
      >
        {hint}
      </PopoverHover>
    </Container>
  );
};

type PropsType = {
  name: string;
  label?: ReactNode;
  labelType?: CheckInputLabelTypeType;
  hint?: string;
  needMargin?: boolean;
  variant?: CheckboxVariantType;
  disabled?: boolean;
};

export type CheckInputLabelTypeType = "default" | "cursive" | "gray";
