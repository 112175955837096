export const rights = (roles: RolesType) => {
  const { employee, department, osr, osrCa, ok, sk, operator, module, superUser } = roles;

  return {
    // ------------------------------ СПРАВОЧНИКИ

    canViewAndEditDictionariesForForm: osrCa || module || superUser,

    canViewDictionariesForEmployee: osr || osrCa || module || superUser,
    canEditDictionariesForEmployee: osrCa || module || superUser,

    canViewAspectGroupsDictionary: osrCa || module || superUser,

    canViewEmployeeDictionary: department || osr || osrCa || module || superUser,
    canViewOrganizationEmployees: osr || osrCa || superUser,
    canViewAllEmployees: module || superUser,
    canEditEmployeeDictionary: osr || osrCa || module || superUser,

    canViewOtherDictionaries: osr || osrCa || operator || module || superUser,
    canEditOtherDictionaries: osrCa || operator || module || superUser,

    canViewSanatoriumsDictionary:
      employee || department || osr || osrCa || ok || sk || operator || module || superUser,
    canEditSanatoriumsDictionary: osrCa || operator || module || superUser,

    // ------------------------------ ЗАЯВКИ

    canViewAllApplications: osr || osrCa || superUser,
    canViewMyApplications: employee || department || osr || osrCa || ok || sk || superUser,
    canViewInboxApplications: osr || osrCa || ok || operator || superUser,
    canViewApplicationsInProgress: osr || osrCa || operator || ok || superUser,
    canViewProcessedApplications: osrCa || osr || ok || sk || operator || superUser,

    canViewAndEditApplication:
      employee || department || osr || osrCa || ok || sk || operator || superUser,

    canViewApplicationRejection: employee || department || osr || osrCa || operator || superUser,
    canEditApplicationRejection: employee || department || superUser,
    canAgreementApplicationRejection: osr || osrCa || superUser,

    canViewAndEditAlternativeApplication: employee || department || osrCa || operator || superUser,

    canCreateApplicationForOtherEmployee: department || osr || osrCa || superUser,

    canDownloadApplications: osr || osrCa || superUser,

    // ------------------------------ РЕЕСТР ЗАЯВОК

    canViewAndEditCreateApplicationRegistry: sk || operator || osr || osrCa || superUser,

    canViewApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canEditApplicationRegistry: osr || osrCa || superUser,
    canSendApplicationRegistryToSk: osr || osrCa || superUser,
    canDeleteApplicationRegistry: osr || osrCa || superUser,

    canApproveApplicationRegistry: sk || superUser,
    canDownloadApplicationRegistry: osr || osrCa || sk || operator || superUser,
    canDownloadIpr: osr || superUser,

    canViewAllApplicationRegistries: sk || osrCa || superUser,

    canMakeDecisionOfCommissionOnApplication: sk || superUser,

    // ------------------------------ РЕЕСТР ПУТЁВОК

    canViewAndEditCreateTravelPackageRegistry: osr || osrCa || superUser,

    canViewTravelPackageRegistry: osr || operator || osrCa || superUser,
    canEditTravelPackageRegistry: osr || osrCa || superUser,
    canDownloadTravelPackageRegistry: osr || osrCa || superUser,

    // ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ

    // canViewFeedback: employee || department || osr || osrCa || superUser,
    // canEditFeedback: employee || department || superUser,
    canViewFeedback: superUser,
    canEditFeedback: superUser,

    // ------------------------------ АДМИНИСТРИРОВАНИЕ

    canViewAdministration: osr || osrCa || module || superUser,
    canViewAndEditPrice: osrCa || module || superUser,
    canViewAndEditFrequency: osrCa || module || superUser,
    canViewReporting: osr || osrCa || superUser,

    // ------------------------------ ПОДБОР/ПРОСМОТР САНАТОРИЯ

    canViewAndEditSanatoriums: employee || department || osr || osrCa || superUser,

    // ------------------------------ САНАТОРИИ

    canViewSanatoriumList: ok || sk || employee || department || osr || osrCa || superUser,
    canChooseSanatorium: superUser || sk || ok || osrCa || osr || department || module || employee,

    // ------------------------------ САНАТОРИЙ

    canViewAndEditSanatorium: module || superUser,
    canEditSanatoriumRating: osrCa || superUser,

    // ------------------------------ УВЕДОМЛЕНИЯ

    canViewNotifications:
      operator || sk || ok || osrCa || osr || department || employee || superUser,

    // ------------------------------ ПРОФИЛЬ

    canViewProfile: employee || department || osr || osrCa || superUser || sk || ok,
    canEditProfile: employee || department || osr || osrCa || superUser || sk || ok,

    canViewOtherProfile: department || osr || osrCa || superUser,
    canEditOtherProfile: department || osr || osrCa || superUser,
  };
};

type RolesType = {
  superUser: boolean;
  operator: boolean;
  sk: boolean;
  ok: boolean;
  osrCa: boolean;
  osr: boolean;
  department: boolean;
  module: boolean;
  employee: boolean;
};

export type RoleModelType = RolesType & ReturnType<typeof rights>;
