import { queryClientAtom } from "jotai/query";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { photosApi, PhotosType } from "../../api";
import { Photo } from "../../components/Photo";
import { QueryKeyType, useQuery } from "../../utils/useQuery";
import { useRhfField } from "../../utils/useRHF";
import { CheckMark, Trash } from "../Icons";
import { Buttons, CheckButton, Photos, RemoveButton } from "./PhotosInput.styles";

export const PhotoList: FC<{ photos: PhotosType; name: string; externalQueryKey: QueryKeyType }> = (
  props
) => {
  const { photos, name, externalQueryKey } = props;

  const { setValue } = useRhfField<PhotosType>(name);

  const handleMain = (i: number) => {
    const data = photos.map((photo, j) => ({ ...photo, isMain: i === j && !photo.isMain }));
    setValue(data);
  };

  const query = useQuery();
  const deletion = query.useDelete({ api: photosApi(), queryKey: ["photosState"] });

  const queryClient = useAtomValue(queryClientAtom);

  const handleRemove = async (id: string, i: number) => {
    const filteredPhotos = photos.filter((_, j) => i !== j);
    setValue(filteredPhotos);

    if (id !== "") {
      await deletion?.mutateAsync([id]);
      queryClient.invalidateQueries(externalQueryKey);
    }
  };

  if (!photos || photos.length === 0) return null;

  return (
    <Photos>
      {photos.map(({ id, file, isMain }, i) => (
        <Photo key={i} file={file} height={117}>
          <Buttons>
            <CheckButton onClick={() => handleMain(i)} check={isMain}>
              <CheckMark />
            </CheckButton>

            <RemoveButton onClick={() => handleRemove(id, i)}>
              <Trash />
            </RemoveButton>
          </Buttons>
        </Photo>
      ))}
    </Photos>
  );
};
