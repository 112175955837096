import { useAtom } from "jotai";
import React, { FC, useEffect, useRef } from "react";
import { PhotosType } from "../../api";
import { statusPhotosInputState } from "../../atoms";
import { preventDefault } from "../../utils/preventDefault";
import { QueryKeyType } from "../../utils/useQuery";
import { useRhfField, useRhfFormInField } from "../../utils/useRHF";
import { CheckMark, ExclamationMark, Export } from "../Icons";
import { PhotoList } from "./PhotoList";
import { Container, Input } from "./PhotosInput.styles";
import { usePhotosInput } from "./usePhotosInput";

export const PhotosInput: FC<PropsType> = (props) => {
  const { label, accept, maxSize, description, externalQueryKey } = props;

  const [status, setStatus] = useAtom(statusPhotosInputState);

  const name = "photos";

  const { trigger } = useRhfFormInField();
  const { value, error } = useRhfField<PhotosType>(name);

  const ref = useRef<HTMLInputElement>(null);

  const methods = usePhotosInput({ name, accept, maxSize });
  const { onChange, handleDrop, element } = methods;

  useEffect(() => {
    if (status === "wasFile") trigger(name);
  }, [status]);

  useEffect(() => {
    value.length && setStatus("wasFile");
    return () => setStatus("empty");
  }, []);

  return (
    <>
      {status === "empty" || status === "wasFile" ? (
        <Container
          status={status}
          onClick={() => ref.current?.click()}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
          onDrop={handleDrop}
        >
          {element({ icon: <Export />, label, description })}
        </Container>
      ) : (
        <Container status={status} as="div">
          {status === "error"
            ? element({ icon: <ExclamationMark />, description: error ?? "" })
            : status === "progress"
            ? element({ needProgress: true })
            : status === "success"
            ? element({
                icon: <CheckMark />,
                label: "Фотографии загружены",
                description: "Обработка завершается...",
              })
            : null}
        </Container>
      )}

      <PhotoList photos={value} name={name} externalQueryKey={externalQueryKey} />

      <Input
        ref={ref}
        name={name}
        type="file"
        accept={accept.join(",")}
        onChange={onChange}
        multiple
      />
    </>
  );
};

type PropsType = {
  label: string;
  accept: string[];
  maxSize: number;
  description: string;
  externalQueryKey: QueryKeyType;
};
