import React, { FC } from "react";
import { ChildrenType } from "../../../../../../../../atoms";
import { ageOptions } from "../../../../../../../../constants/options";
import { CheckInput, SelectInput } from "../../../../../../../../uiKit";
import { useRhfField } from "../../../../../../../../utils/useRHF";
import { Container, SelectContainer } from "./ChildrenInput.styles";

export const ChildrenInput: FC<PropsType> = (props) => {
  const { name } = props;

  const { value } = useRhfField<ChildrenType>(name);

  return value.length ? (
    <Container>
      {Array.from(value).map((_, i) => (
        <SelectContainer key={i}>
          <SelectInput
            name={`${name}.${i}.age`}
            options={ageOptions}
            placeholder="Выберите"
            size={160}
          />

          <CheckInput
            name={`${name}.${i}.isNotOwn`}
            label="Не сын/дочь"
            needMargin={false}
            labelType="cursive"
          />
        </SelectContainer>
      ))}
    </Container>
  ) : null;
};

type PropsType = {
  name: string;
};
