import React, { FC } from "react";
import { Error } from "../../../styles";
import { OptionType } from "../../../utils/getOptions";
import { useRhfField } from "../../../utils/useRHF";
import { Radio } from "../../Icons";
import * as elements from "./RadioInput.styles";

export const RadioInput: FC<PropsType> = (props) => {
  const { name, title, options, isColumn = false } = props;
  const { Container, RadioTitle, Text, Title, Wrapper } = elements;
  const { RadioWrapper, RadioDescription } = elements;

  const { value, setValue, error } = useRhfField<string>(name);

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}

      <Container isColumn={isColumn}>
        {options.map(({ id, name, description, disabled }) => (
          <RadioWrapper key={id}>
            <RadioTitle onClick={() => !disabled && setValue(String(id))}>
              <Radio checked={value === id} disabled={disabled} />
              <Text disabled={disabled}>{name}</Text>
            </RadioTitle>
            <RadioDescription>{description}</RadioDescription>
          </RadioWrapper>
        ))}
      </Container>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

type PropsType = {
  name: string;
  title?: string;
  options: RadioOptionsType;
  isColumn?: boolean;
};

type RadioOptionsType = (OptionType & {
  disabled?: boolean;
})[];
