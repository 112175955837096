import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { opacify } from "polished";
import { MutableRefObject } from "react";
import { borders, colors, flex, margins, scroll, size, text } from "../../../styles";

const { black, grayscaleDisabled, grayscaleInput, grayscaleAsh, grayscaleBeautifulBlack } = colors;
const { grayscalePlaceholder, grayscaleLabel, redBackground } = colors;

export const Wrapper = styled.div`
  position: relative;
`;

export const ResetButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 9;
`;

export const TextInputContainer = styled.div<ContainerPropsType>`
  ${({ multiline }) =>
    flex({ gap: 16, isColumn: multiline, vertical: multiline ? "top" : "center" })};
  ${borders({ radius: 16 })};
  ${({ hasLabel, multiline }) =>
    margins({ p: hasLabel ? (multiline ? "22 16 16 16" : "22 16 10 16") : "16" })}

  position: ${({ internal }) => (internal ? "absolute" : "relative")};
  ${({ internal }) => (internal ? "top: 6px; left: 58px;" : "")};
  background: ${({ hasError, disabled }) =>
    hasError ? redBackground : disabled ? grayscaleDisabled : grayscaleInput};

  ${({ prependContainerRef, postfix, value }) => {
    const div = document.createElement("div");
    div.style.width = "fit-content";
    div.style.fontSize = "16px";
    div.style.lineHeight = "24px";
    div.style.fontWeight = "400";
    div.innerHTML = String(value);
    document.body.appendChild(div);
    const width = div.clientWidth;
    const prependWidth = prependContainerRef?.current?.clientWidth;
    document.body.removeChild(div);

    return !!postfix && value !== "" && String(value) !== "0"
      ? `
      ::before {
        content: "${postfix ?? ""}";
        position: absolute;
        top: 23px;
        left: calc(16px + 4px + ${(prependWidth ? prependWidth + 16 : 0) + width}px);
      }`
      : "";
  }}
`;

const inputStyle = () => css`
  ${borders({ none: true })};
  ${text({ size: 16, height: 24, weight: 400, color: grayscaleAsh })};
  ${margins({ p: "0" })};

  background: transparent;
  outline: none;

  ::placeholder {
    color: ${grayscalePlaceholder};
  }

  :disabled {
    background: ${grayscaleDisabled};
    color: ${opacify(-0.5, grayscaleAsh)};
    cursor: no-drop;
  }

  :disabled::placeholder {
    color: ${opacify(-0.5, grayscalePlaceholder)};
  }
`;

export const Input = styled.input<InputPropsType>`
  ${inputStyle};
  ${({ internal }) => size({ h: 24, w: internal ? 350 : "100%" })};

  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    ${margins({ m: "0" })};

    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }
`;

export const MultilineInput = styled.textarea<InputPropsType>`
  ${size({ h: 150, w: "100%" })};
  ${inputStyle};
  ${scroll};

  resize: none;
`;

export const Label = styled.p<{ multiline: boolean }>`
  ${size({ max: { w: "100%" } })};
  ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
  ${margins({ m: "0" })};

  position: absolute;
  left: 16px;
  top: 6px;
  transform: none;

  :focus {
    color: ${grayscaleLabel};
  }
`;

export const Title = styled.h5`
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 0 8 0" })};
`;

export const Prepend = styled.div<{ multiline: boolean }>`
  ${({ multiline }) => (multiline ? borders({ side: "left", color: black }) : undefined)};
  ${text({ size: 16, height: 23, weight: 400, color: grayscaleAsh })};
  ${({ multiline }) => margins({ p: multiline ? "0 0 0 16" : "0" })};
`;

type ContainerPropsType = PropsType & {
  prependContainerRef: MutableRefObject<HTMLDivElement | null>;
  postfix?: string;
  value: string | number;
  multiline: boolean;
};

type InputPropsType = PropsType & {
  heightPrepend: number;
};

type PropsType = {
  hasLabel: boolean;
  hasError: boolean;
  internal: boolean;
  disabled: boolean;
};
