import React, { FC } from "react";
import { Check } from "../../../uiKit";
import { useTreatmentProfileOptions } from "../../../utils/getOptions";
import { useRhfField } from "../../../utils/useRHF";
import { Fields, Item, ItemText, Title } from "./TreatmentProfileFilterFields.styles";

export const TreatmentProfileFilterFields: FC<PropsType> = (props) => {
  const { name } = props;

  const treatmentProfileOptions = useTreatmentProfileOptions();

  const { value: values, setValue } = useRhfField<string[]>(name);

  const handleChange = (id: string) => {
    const filteredValues = values.filter((valueId) => valueId !== id);
    values.includes(id) ? setValue(filteredValues) : setValue([...values, id]);
  };

  return (
    <Fields>
      <Title>Профиль лечения</Title>

      {treatmentProfileOptions.map(({ id, name }) => (
        <Item key={id} onClick={() => handleChange(id)}>
          <Check variant="filled" checked={values?.includes(id)} />
          <ItemText>{name}</ItemText>
        </Item>
      ))}
    </Fields>
  );
};

type PropsType = {
  name: string;
};
