import styled from "@emotion/styled";
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { colors, size, text } from "../../../styles";
import { useRhfField } from "../../../utils/useRHF";
import { CloseButton } from "../../Buttons";
import * as elements from "./TextInput.styles";

const TextInput: FC<PropsType> = (props) => {
  const { type = "text", name, title, label, placeholder = "", multiline = false } = props;
  const { prepend, postfix, autoFocus = false, disabled = false, internal = false } = props;

  const { Wrapper, ResetButton, TextInputContainer, Input, Label, Title, MultilineInput, Prepend } =
    elements;

  const { value, error, setValue, onChange, onBlur, trigger } = useRhfField<string>(name);
  const [heightPrepend, setHeightPrepend] = useState(0);

  const hasError = !!error;

  const generalProps = {
    name,
    value: type === "number" && value === null ? "" : value,
    hasLabel: !!label,
    placeholder,
    onChange,
    onBlur,
    disabled,
    internal,
    hasError,
    autoComplete: "off",
  };

  const handleKeyPress =
    type === "number"
      ? (evt: KeyboardEvent<HTMLInputElement>) =>
          evt?.key === "-" || evt?.key === "+" || evt?.key === "e"
            ? evt.preventDefault()
            : undefined
      : undefined;

  const handleBlur = () => {
    trigger(name);
    onBlur();
  };

  const prependContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setHeightPrepend(prependContainerRef?.current?.clientHeight ?? 0);
  }, [prepend, prependContainerRef]);

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}

      {multiline && !!value && (
        <ResetButton>
          <CloseButton onClick={() => setValue("")} type="cross" inverted />
        </ResetButton>
      )}

      <TextInputContainer
        {...generalProps}
        postfix={type === "number" && value === null ? "" : postfix}
        prependContainerRef={prependContainerRef}
        multiline={multiline}
      >
        <Label multiline={multiline}>{label}</Label>

        {prepend ? (
          <Prepend ref={prependContainerRef} multiline={multiline}>
            {prepend}
          </Prepend>
        ) : null}

        {multiline ? (
          <MultilineInput {...generalProps} heightPrepend={heightPrepend} autoFocus={autoFocus} />
        ) : (
          <Input
            type={type}
            heightPrepend={heightPrepend}
            {...generalProps}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            autoComplete="off"
            autoFocus={autoFocus}
          />
        )}

        {multiline && value?.length <= 1000 && (
          <Description>
            {value?.length}/1000
            {value?.length === 1000 ? " – Достигнуто допустимое количество символов" : ""}
          </Description>
        )}

        {hasError && (
          <Error>
            {multiline && error === "Слишком длинное значение"
              ? `${value?.length}/1000 – Достигнуто допустимое количество символов`
              : error}
          </Error>
        )}
      </TextInputContainer>
    </Wrapper>
  );
};

export default TextInput;

type PropsType = {
  type?: "text" | "number";
  name: string;
  label?: string;
  title?: string;
  placeholder?: string;
  multiline?: boolean;
  prepend?: JSX.Element;
  postfix?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  internal?: boolean;
};

const Error = styled.p<{ bottom?: number }>`
  ${size({ w: "95%" })};
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  left: 16px;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-12px")};
  white-space: nowrap;
  z-index: 1;
`;

const Description = styled.p<{ bottom?: number }>`
  ${size({ w: "95%" })};
  ${text({ size: 12, height: 12, weight: 400, color: colors.grayscaleLabel })};

  position: absolute;
  left: 16px;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-12px")};
  white-space: nowrap;
  z-index: 1;
`;
