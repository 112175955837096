import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { atomWithStorage, loadable } from "jotai/utils";
import { AlternativeRoomsStateType, applicationsApi, ApplicationWithTaskType } from "../../api";
import { fetchById } from "../../utils/fetchData";
import { applicationIdState } from "../index";

export const applicationFromAppPageState = atom<ApplicationWithTaskType | undefined>(undefined);
export const alternativeRoomsState = atomWithStorage<AlternativeRoomsStateType>(
  "alternativeRooms",
  []
);

// ------------------------------ ЗАЯВКА

export const applicationAtom = atomWithQuery((get) => {
  const id = get(applicationIdState);

  return {
    queryKey: [id, "applicationState"],
    queryFn: () => fetchById(() => applicationsApi().get(id ?? "")),
    enabled: !!id,
  };
});

export const applicationState = loadable(applicationAtom);
