import { AxiosResponse } from "axios";
import { hrApi, hrServicesApi, psApi } from "./api";
import { ContactsForProfileType } from "./contacts";
import { OtherWithIconType } from "./dictionaries";
import { employeesFetchPlans, NestedValueOf } from "./fetchPlans";
import { DocumentsType } from "./file";
import { entitiesBase } from "./request";
import * as types from "./types";

// ------------------------------ КАРТОЧКА РАБОТНИКА

export const employeesApi = <E>() => ({
  ...entitiesBase<ResortEmployeeType, E, EmployeesRequestType>(hrApi, "resort_Employee"),

  get: async (
    personnelNumber: string,
    fetchPlan?: NestedValueOf<typeof employeesFetchPlans>
  ): Promise<AxiosResponse<EmployeeType>> => {
    const conditions = [{ property: "personnelNumber", operator: "=", value: personnelNumber }];

    const args = { fetchPlan, filter: conditions, startIndex: 0, size: 1 };
    const resortEmployee = (await employeesApi().getAll(args)).data.items[0];

    if (!resortEmployee)
      return Promise.reject(
        `Не найден работник "resort_Employee" по personnelNumber = ${personnelNumber}`
      );

    const orgEmployee = await orgEmployeesApi().get(personnelNumber);
    const { data, ...orgEmployeeWithoutData } = orgEmployee;

    return { ...orgEmployeeWithoutData, data: { ...resortEmployee, ...data } };
  },

  getVipEmployeeIds: (): Promise<AxiosResponse<string[]>> =>
    hrServicesApi.get("/employeeService/getVipEmployeePersonnelNumbers"),
});

export const orgEmployeesApi = <E>() => ({
  ...entitiesBase<OrgEmployeeType, E, EmployeesRequestType>(psApi, "employees"),

  getAll: async (params: EmployeesRequestType): Promise<AxiosResponse<EmployeesResponseType>> => {
    const res = (await psApi.get("/employees/all", { params })) as AxiosResponse<EmployeesType>;
    const totalCount = (await psApi.get("/employees/total_count", { params })).data;

    const { data: items, ...employeesWithoutData } = res;

    return { ...employeesWithoutData, data: { items, totalCount } };
  },
});

// ------------------------------ ТИПЫ - РАБОТНИК

export type PositionType = {
  code: string;
  name: string;
  description: string;
  shortName: string;
  department: DepartmentType;
};

type DepartmentType = {
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent?: string;
  location?: LocationType;
};

type OrganizationType = {
  code: string;
  name: string;
};

export type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;

  name: string;
};

export type ResortEmployeesType = ResortEmployeeType[];

export type OrgEmployeesType = OrgEmployeeType[];

export type OrgEmployeeType = types.NameType & {
  orgId: string;
  personnelNumber: string;
  sex?: types.SexType;
  birthDate: types.BackendDateType;
  position?: PositionType;
  functionalPosition: PositionType | null;
  status?: types.EmployeeStatusType;
  location?: LocationType;
  address?: string;
  contacts: ContactsForProfileType;
  insuranceCode?: string;
  created?: string;
  startPositionDate: types.BackendDateType;
  employmentDate?: types.BackendDateType;
};

export type EmployeeType = ResortEmployeeType & OrgEmployeeType;

export type ResortEmployeeType = types.IdType &
  types.NameType & {
    active: boolean;
    createdDate: Date;
    department: string;
    departmentName: string;
    email: string;
    lastModifiedDate: string;
    organization: string;
    organizationName: string;
    employmentDate: types.BackendDateType;
    personnelNumber: string;
    positionName: string;
    username: string;
    vip: boolean;
    documents?: DocumentsType;
    factor?: OtherWithIconType;
  };

// ----- передаваемые значения

export type EmployeesRequestType = Partial<types.EntityRequestBaseType> & {
  year?: number;
  mainDepartment?: string;
  organization?: string;
  status?: string;
  export?: string;
  disabilityId?: string;
  diseaseClassId?: string;
  startDateOfDiseasePeriod?: types.BackendDateType;
  endDateOfDiseasePeriod?: types.BackendDateType;
  personnelNumbers?: string;
};

// ----- получаемые значения

export type EmployeesResponseType = { items: EmployeesType; totalCount: number };

export type EmployeesType = EmployeeType[];
