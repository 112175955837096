import { CircularProgress } from "@mui/material";
import { useAtom } from "jotai";
import React, { ChangeEvent, DragEvent, RefObject, useState } from "react";
import { statusIconInputState } from "../../atoms";
import { preventDefault } from "../../utils/preventDefault";
import { useRhfField } from "../../utils/useRHF";
import { validateIcon } from "../../utils/validateIcon";
import * as elements from "./IconInput.styles";

const { Title, Text, Description, Progress, Percent } = elements;
const { Button, Icon, LoadBarAndText, LoadBar } = elements;

export const useIconInput = (name: "icon") => {
  // ------------------------------ АТОМЫ

  const [status, setStatus] = useAtom(statusIconInputState);

  // ------------------------------ СТЕЙТЫ

  const [progress, setProgress] = useState(0);
  const [timerSuccess, setTimerSuccess] = useState<ReturnType<typeof setTimeout>>();
  const [timerIdLoaded, setTimerIdLoaded] = useState<ReturnType<typeof setTimeout>>();
  const [timerProgress, setTimerProgress] = useState<ReturnType<typeof setInterval>>();

  // ------------------------------ РАБОТА С ФОРМОЙ

  const { setValue } = useRhfField<File | undefined>(name);

  const onChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const icon = evt.target.files?.[0];

    if (!icon) return;

    await addIcon(icon);
  };

  const handleRemove = (ref: RefObject<HTMLInputElement>) => {
    setProgress(0);

    clearTimeout(timerSuccess as ReturnType<typeof setTimeout>);
    clearTimeout(timerIdLoaded as ReturnType<typeof setTimeout>);
    clearInterval(timerProgress as ReturnType<typeof setInterval>);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const input = ref.current!;
    input.value = "";
    setValue(undefined);
    setTimeout(() => {
      setValue(undefined);
      setStatus("empty");
    }, 0);
  };

  const handleDrop = (evt: DragEvent<Element>) => {
    preventDefault(evt);
    const icon = evt.dataTransfer.files?.[0];
    addIcon(icon);
  };

  // ------------------------------ ДОБАВЛЕНИЕ ИКОНКИ

  const addIcon = async (icon: File | undefined) => {
    if (!icon) return;

    setValue(icon);

    const iconInvalid = await validateIcon(icon);

    if (iconInvalid) {
      setStatus("error");
      return;
    }

    setStatus("progress");

    const intervalTimer = setInterval(() => {
      setProgress((prevState) => (prevState >= 100 ? 0 : prevState + 20));
    }, 200);

    setTimerProgress(intervalTimer);

    setTimerSuccess(setTimeout(() => setStatus("success"), 1000));
    setTimerIdLoaded(setTimeout(() => setStatus("wasFile"), 2000));

    setTimeout(() => {
      setProgress(0);
      clearInterval(intervalTimer as ReturnType<typeof setInterval>);
    }, 1000);
  };

  // ------------------------------ ОБОБЩЕННЫЙ ЭЛЕМЕНТ

  const element = (props: PropsType) => {
    const { icon, buttonIcon, onClick, needProgress, needEmptiness, title, description } = props;

    return (
      <>
        <LoadBarAndText status={status}>
          {icon ? <LoadBar status={status}>{icon}</LoadBar> : null}
          {needProgress ? (
            <Progress>
              <CircularProgress variant="determinate" value={progress} />
              <Percent>{`${Math.round(progress)}%`}</Percent>
            </Progress>
          ) : null}
          {title || description ? (
            <Text>
              {title ? <Title status={status}>{title}</Title> : null}
              {description ? <Description status={status}>{description}</Description> : null}
            </Text>
          ) : null}
        </LoadBarAndText>

        {buttonIcon && onClick ? <Button onClick={onClick}>{buttonIcon}</Button> : null}
        {needEmptiness ? <Icon /> : null}
      </>
    );
  };

  return {
    onChange,
    handleRemove,
    handleDrop,
    element,
  };
};

type PropsType = {
  icon?: JSX.Element;
  buttonIcon?: JSX.Element;
  onClick?: () => void;
  needEmptiness?: boolean;
  needProgress?: boolean;
  title?: string;
  description?: string;
};
