import React, { FC, ReactNode, useState } from "react";
import { colors, Error } from "../../styles";
import { OptionsType } from "../../utils/getOptions";
import { useRhfField } from "../../utils/useRHF";
import { CrossInCircle, TriangleDown } from "../Icons";
import { PopoverHover } from "../PopoverHover";
import * as elements from "./SelectAutocompleteMultilineInput.styles";
import { SelectedItem } from "./SelectedItem";
import { useSelectAutocompleteMultilineInput } from "./useSelectAutocompleteMultilineInput";

export const SelectAutocompleteMultilineInput: FC<SelectAutocompleteMultilineInputPropsType> = (
  props
) => {
  const { name, label, title, size = "", disabled, category, clearable } = props;
  const { result = "list", color = colors.grayscaleInput } = props;

  const inputMethods = useSelectAutocompleteMultilineInput(props);
  const { autocompleteValue, renderInput, renderOption, markOptions, handleChange } = inputMethods;

  const { Container, AutocompleteItem, SelectedOptions } = elements;
  const { Wrapper, Title, PaperProps } = elements;

  const { value: values, error, onBlur } = useRhfField<string[]>(name);

  const hasError = !!error;

  const valuesQuantity = values.length;

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}

      <Container>
        <AutocompleteItem
          value={autocompleteValue}
          options={markOptions}
          openText=""
          closeText=""
          groupBy={category ? (markOption) => markOption.category : undefined}
          getOptionLabel={(option) => option?.option.name as string}
          onChange={(evt, newValue) => {
            handleChange(evt, newValue);

            if (newValue === null || (Array.isArray(newValue) && newValue.length === 0)) {
              setAnchorEl(null);
            }
          }}
          onBlur={onBlur}
          renderInput={renderInput}
          renderOption={renderOption}
          popupIcon={<TriangleDown />}
          clearIcon={<CrossInCircle color={colors.grayscaleIcons} />}
          clearText=""
          disableCloseOnSelect
          componentsProps={{ paper: PaperProps(size, !!category) }}
          noOptionsText="Нет совпадений"
          isOptionEqualToValue={(option, value) => option?.option.id === value?.option.id}
          haslabel={+!!label}
          haserror={+hasError}
          disabled={disabled}
          color={color}
          disableClearable={!clearable}
          hiddenpopupbutton={+(!!clearable && !!valuesQuantity)}
          inputcolor={valuesQuantity ? colors.grayscaleAsh : colors.grayscalePlaceholder}
          multiple
          onMouseEnter={(evt) =>
            result === "text"
              ? valuesQuantity &&
                setAnchorEl((evt as React.MouseEvent<HTMLInputElement>).currentTarget)
              : undefined
          }
          onMouseLeave={() => (result === "text" ? setAnchorEl(null) : undefined)}
        />

        {hasError && <Error bottom={-6}>{error}</Error>}

        {result === "list" && (
          <SelectedOptions>
            {values.map((value) => (
              <SelectedItem key={value} id={value} inputMethods={inputMethods} />
            ))}
          </SelectedOptions>
        )}

        {result === "text" ? (
          <PopoverHover
            id={`popover-hover-${name}`}
            isOpen={!!anchorEl}
            element={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: 6, horizontal: "center" }}
          >
            {values
              .map((id) => markOptions.find((el) => el.option.id === id)?.option.name)
              .join(", ")}
          </PopoverHover>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export type SelectAutocompleteMultilineInputPropsType = {
  name: string;
  options: OptionsType | OptionsType[];
  category?: string[];
  label?: string;
  title?: string;
  placeholder: string;
  size?: string;
  disabled?: boolean;
  result?: "text" | "list";
  color?: string;
  iconStart?: ReactNode;
  clearable?: boolean;
};
