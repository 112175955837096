import React, { FC, MouseEvent } from "react";
import { Square } from "../../Icons";
import { Container } from "./SelectAllButton.styles";

export const SelectAllButton: FC<PropsType> = (props) => {
  const { onClick, disabled = false } = props;

  return (
    <Container onClick={onClick} disabled={disabled}>
      <div>
        <Square />
      </div>
    </Container>
  );
};

type PropsType = {
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
};
