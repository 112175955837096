import { useAtomValue } from "jotai/utils";
import React, { ReactNode } from "react";
import {
  applicationsInProgressTotalCountState,
  currentUserState,
  inboxApplicationsTotalCountState,
} from "../atoms";
import { Book, BuildingAndTree, Folder, Gear, Loupe, ThumbUp } from "../uiKit";
import * as routes from "./routes";

export const useSections = () => {
  const currentUserData = useAtomValue(currentUserState);
  const roles = currentUserData.state === "hasData" ? currentUserData.data.roleModel : null;

  const inboxApplicationsTotalCountLoadable = useAtomValue(inboxApplicationsTotalCountState);
  const applicationsInProgressTotalCountLoadable = useAtomValue(
    applicationsInProgressTotalCountState
  );

  const inboxApplicationsTotalCountData =
    inboxApplicationsTotalCountLoadable.state === "hasData"
      ? inboxApplicationsTotalCountLoadable.data
      : 0;
  const applicationsInProgressTotalCountData =
    applicationsInProgressTotalCountLoadable.state === "hasData"
      ? applicationsInProgressTotalCountLoadable.data
      : 0;

  if (!roles) return [];

  const canViewDictionaries =
    roles.canViewAndEditDictionariesForForm ||
    roles.canViewDictionariesForEmployee ||
    roles.canViewEmployeeDictionary ||
    roles.canViewOtherDictionaries;

  const canViewAndEditLifecycle =
    roles.canViewAllApplications ||
    roles.canViewMyApplications ||
    roles.canViewInboxApplications ||
    roles.canViewApplicationsInProgress ||
    roles.canViewProcessedApplications ||
    roles.canViewApplicationRegistry;

  const sections = [
    // ------------------------------ САНАТОРИИ

    ...(roles.canViewSanatoriumList
      ? [
          {
            type: "section",
            name: "sanatoriums",
            text: "Поиск",
            rout: routes.SANATORIUMS,
            icon: <Loupe />,
            not: "dictionaries",
            additionalRoutes: ["application/not"],
          },
        ]
      : []),

    ...(roles.canViewSanatoriumsDictionary || canViewDictionaries
      ? [
          {
            type: "section",
            name: "dictionaries/sanatoriums",
            text: "Справочник санаториев",
            rout: routes.DICTIONARY_SANATORIUMS,
            icon: <BuildingAndTree />,
          },
        ]
      : []),

    // ------------------------------ СПРАВОЧНИКИ

    ...(canViewDictionaries
      ? [
          {
            type: "folder",
            name: "dictionaries",
            text: "Справочники",
            rout: routes.DICTIONARY,
            icon: <Folder />,
            not: "sanatoriums",
          },
        ]
      : []),

    ...(roles.canViewEmployeeDictionary
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "employees",
            text: "Сотрудники",
            rout: routes.DICTIONARY_EMPLOYEES,
          },
        ]
      : []),

    ...(roles.canViewOtherDictionaries
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "countries",
            text: "Страны",
            rout: routes.DICTIONARY_COUNTRIES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "regions",
            text: "Регионы",
            rout: routes.DICTIONARY_REGIONS,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "treatment-profiles",
            text: "Профили лечения",
            rout: routes.DICTIONARY_TREATMENT_PROFILE,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "treatment-methods",
            text: "Методы лечения",
            rout: routes.DICTIONARY_TREATMENT_METHODS,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "sanatorium-services",
            text: "Услуги санатория",
            rout: routes.DICTIONARY_SANATORIUM_SERVICES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "typical-medical-programs",
            text: "Типовые медицинские программы",
            rout: routes.DICTIONARY_TYPICAL_MEDICAL_PROGRAMS,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "rehab-activity",
            text: "Реабилитационно-оздоровительные мероприятия",
            rout: routes.DICTIONARY_REHAB_ACTIVITY,
          },
        ]
      : []),

    ...(roles.canViewDictionariesForEmployee
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "working-conditions-classes",
            text: "Классы условий труда",
            rout: routes.DICTIONARY_WORKING_CONDITIONS,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "occupational-factors",
            text: "Факторы производственной среды",
            rout: routes.DICTIONARY_OCCUPATIONAL_FACTORS,
          },
        ]
      : []),

    ...(roles.canViewAspectGroupsDictionary
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "aspect-groups",
            text: "Группы аспектов",
            rout: routes.DICTIONARY_ASPECT_GROUPS,
          },
        ]
      : []),

    ...(roles.canViewAndEditDictionariesForForm
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "sanatorium-aspects",
            text: "Аспекты работы СКО",
            rout: routes.DICTIONARY_SANATORIUM_ASPECTS,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "improve-services",
            text: "Совершенствование услуг и работы СКО",
            rout: routes.DICTIONARY_IMPROVE_SERVICES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "patient-outcomes",
            text: "Результативность лечения",
            rout: routes.DICTIONARY_PATIENT_OUTCOMES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "statuses",
            text: "Статусы",
            rout: routes.DICTIONARY_STATUSES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "document-types",
            text: "Типы документов",
            rout: routes.DICTIONARY_DOCUMENT_TYPES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "why-not",
            text: "Причины отказа",
            rout: routes.DICTIONARY_WHY_NOT,
          },
        ]
      : []),

    // ------------------------------ ЖИЗНЕННЫЙ ЦИКЛ ЗАЯВКИ

    ...(canViewAndEditLifecycle
      ? [
          {
            type: "folder",
            name: "applications",
            text: "Заявки на СКЛ и РОМ",
            rout: routes.APPLICATIONS,
            icon: <Book />,
            not: "application/not",
          },
        ]
      : []),

    ...(roles.canViewAllApplications
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "all-applications",
            text: "Все заявки",
            rout: routes.APPLICATIONS_ALL,
          },
        ]
      : []),
    ...(roles.canViewMyApplications
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "my-applications",
            text: "Мои заявки",
            rout: routes.APPLICATIONS_MY,
          },
        ]
      : []),
    ...(roles.canViewInboxApplications
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "inbox-applications",
            text: "Входящие заявки",
            rout: routes.APPLICATIONS_INBOX,
            count: inboxApplicationsTotalCountData,
          },
        ]
      : []),
    ...(roles.canViewApplicationsInProgress
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "in-progress-applications",
            text: "Заявки в работе",
            rout: routes.APPLICATIONS_IN_PROCESS,
            count: applicationsInProgressTotalCountData,
          },
        ]
      : []),
    ...(roles.canViewProcessedApplications
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "processed-applications",
            text: "Обработанные заявки",
            rout: routes.APPLICATIONS_PROCESSED,
          },
        ]
      : []),
    ...(roles.canViewApplicationRegistry
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "registries-applications",
            text: "Реестры заявок",
            rout: routes.REGISTRIES_APPLICATION,
          },
        ]
      : []),
    ...(roles.canViewTravelPackageRegistry
      ? [
          {
            folder: "applications",
            type: "subsection",
            name: "registries-travel-package",
            text: "Реестры путевок",
            rout: routes.REGISTRIES_TRAVEL_PACKAGE,
          },
        ]
      : []),

    // ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ

    ...(roles.canViewFeedback
      ? [
          {
            type: "section",
            name: "feedback",
            text: "Контроль реализации СКЛ",
            rout: routes.FEEDBACK,
            icon: <ThumbUp />,
          },
        ]
      : []),

    // ------------------------------ АДМИНИСТРИРОВАНИЕ

    ...(roles.canViewAdministration
      ? [
          {
            type: "folder",
            name: "administration",
            text: "Администрирование",
            rout: routes.ADMINISTRATION,
            icon: <Gear />,
          },
          ...(roles.canViewAndEditPrice
            ? [
                {
                  folder: "administration",
                  type: "subsection",
                  name: "calculation-settings",
                  text: "Настройки расчёта путёвки",
                  rout: routes.CALCULATION_SETTINGS,
                },
              ]
            : []),
          ...(roles.canViewAndEditFrequency
            ? [
                {
                  folder: "administration",
                  type: "subsection",
                  name: "frequency-settings",
                  text: "Кратность предоставления путевок",
                  rout: routes.FREQUENCY_SETTINGS,
                },
              ]
            : []),
          ...(roles.canViewReporting
            ? [
                {
                  folder: "administration",
                  type: "subsection",
                  name: "reporting",
                  text: "Отчётность",
                  rout: routes.REPORTING(undefined),
                },
              ]
            : []),
        ]
      : []),
  ] as SectionsType;

  return sections;
};

export type SectionType = {
  type: "folder" | "section" | "subsection";
  name: string;
  text: string;
  rout: string;
  additionalRoutes?: string[];
  icon?: ReactNode;
  folder?: "dictionaries" | "applications" | "administration";
  not?: string;
  count?: number;
};

export type SectionsType = SectionType[];
