import React, { FC, MouseEvent } from "react";
import { OptionsType } from "../../utils/getOptions";
import { DocumentList } from "./DocumentList";
import { Input } from "./DocumentsInput.styles";
import { InputEmpty } from "./InputEmpty";
import { InputError } from "./InputError";
import { InputProgress } from "./InputProgress";
import { InputSuccess } from "./InputSuccess";
import { useDocumentsInput } from "./useDocumentsInput";

/**
 *
 * ------------------------------------------------------------------------------------------
 * КОМПОНЕНТ ЗАГРУЗКИ ФАЙЛОВ
 * не стоит использовать для фото и иконок
 *
 * -
 *
 * @param props - параметры
 * @param props.name - имя поля (по-умолчанию "files") (не обязательный)
 * @param props.forEditForm - указывает на то, что поле используется в форме редактирования, т.е. не требуется отображение области загрузки (по-умолчанию false) (не обязательный)
 * @param props.accept - форматы документов в виде массива строк в формате ".док" (по-умолчанию [".pdf"]) (не обязательный)
 * @param props.maxSize - максимальный размер одного файла (по-умолчанию 10485760) (не обязательный)
 * @param props.onDownloadFromProfile  - метод выполняемый при клике на кнопку загрузки из профиля (не обязательный)
 * @param props.hideDocumentList - указывает на то, нужно ли скрыть список документов (по-умолчанию false) (не обязательный)
 * @param props.oneDocument - указывает на то, что возможна загрузка только одного документа (по-умолчанию true) (не обязательный)
 * @param props.options - список опций для выбора дополнительных параметров документов (не обязательный)
 * @param props.optionsLabel - наименование списка опций (не обязательный)
 *
 */

export const DocumentsInput: FC<PropsType> = (props) => {
  const { accept = [".pdf"], maxSize = 10485760, options } = props;
  const { optionsLabel, forEditForm = false, name = "files", onDownloadFromProfile } = props;
  const { hideDocumentList = false, oneDocument = false } = props;

  const methods = useDocumentsInput({ name, accept, maxSize, oneDocument });

  const { ref, documents, progress, status, error, onChange } = methods;
  const { fileNames, filesSize, addFile, handleRemove } = methods;

  const args = { element: ref, progress, error, fileNames, filesSize, handleRemove, name };

  return (
    <>
      {forEditForm ? null : status === "error" ? (
        <InputError {...args} />
      ) : status === "progress" ? (
        <InputProgress {...args} />
      ) : status === "success" ? (
        <InputSuccess />
      ) : oneDocument && documents?.length === 1 ? null : (
        <InputEmpty
          accept={accept}
          maxSize={maxSize}
          element={ref}
          addFile={addFile}
          onDownloadFromProfile={onDownloadFromProfile}
        />
      )}

      {documents?.length && !hideDocumentList ? (
        <DocumentList
          name={name}
          documents={documents}
          forEditForm={forEditForm}
          options={options}
          optionsLabel={optionsLabel}
        />
      ) : null}

      <Input
        ref={ref}
        name={name}
        type="file"
        accept={accept.join(",")}
        onChange={onChange}
        multiple={!oneDocument}
      />
    </>
  );
};

type PropsType = {
  name?: string;
  forEditForm?: boolean;
  accept?: string[];
  maxSize?: number;
  hideDocumentList?: boolean;
  onDownloadFromProfile?: (evt: MouseEvent<HTMLDivElement>) => void;
  oneDocument?: boolean;
  options?: OptionsType;
  optionsLabel?: string;
};
