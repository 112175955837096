import {
  Path,
  useController,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormProps,
  useWatch,
} from "react-hook-form";

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ ПОЛЯ ФОРМЫ
 *
 * -
 *
 * @param name - имя поля (string) (обязательный)
 *
 * @return object.value - текущее значение поля (D)
 * @return object.getValue - получение актуального на данный момент значения поля (() => D)
 * @return object.setValue - установка значения поля принудительно ((data: D) => void)
 * @return object.error - ошибка поля (string | undefined)
 * @return object.setError - установка ошибки поля принудительно ((message: string) => void)
 * @return object.touched - состояние касания для поля (boolean)
 * @return object.onChange - функция, отправляющая поле в библиотеку ((...event: any[]) => void)
 * @return object.onBlur - функция, отправляющая событие onBlur поля в библиотеку (() => void))
 *
 */

export const useRhfField = <D>(name: string) => {
  const arg = { name };

  const context = useFormContext();
  const { setValue: setAnyValue, setError: setAnyError, getValues } = context;
  const { trigger, reset } = context;
  const { field, fieldState } = useController(arg);
  const watchedValue = useWatch({ name });

  // ------------------------------ значение поля

  const value = watchedValue as D;

  const getValue = (): D => getValues(name);

  // ------------------------------ установка значения поля

  const setValue = (data: D) => setAnyValue(name, data);

  // ------------------------------ очистка поля

  const fieldReset = () => reset({ fieldName: name });

  // ------------------------------ ошибка поля

  const { error: errorObj } = fieldState;
  const error = errorObj?.message;

  // ------------------------------ установка ошибки поля

  const setError = (message: string) => setAnyError(name, { type: "manual", message });

  // ------------------------------ тронуто ли поле

  const { isTouched: touched } = fieldState;

  // ------------------------------ событие изменения значения

  const { onChange } = field;

  // ------------------------------ событие потери фокуса

  const { onBlur } = field;

  // ------------------------------ логирование на время разработки

  // const { isDirty } = fieldState;
  // const { isValid } = formState;

  // console.log(
  // `name-${name} |`,
  // `error-${error} |`,
  // `isValid-${isValid} |`,
  // `value-${value} |`,
  // `typeof_value-${typeof value} |`,
  // `isDirty-${isDirty}`
  // );

  return {
    value,
    getValue,
    getValues,
    setValue,
    error,
    setError,
    touched,
    onChange,
    onBlur,
    trigger,
    fieldReset,
  };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ МАССИВА ПОЛЕЙ ФОРМЫ
 *
 * -
 *
 * @param name - имя массива полей (string) (обязательный)
 *
 * @return object.values - это object содержит defaultValue и key для вашего компонента (D[] | [])
 * @return object.addValue - ф-я добавления поля в конец ((value: D) => void)
 * @return object.replaceValue - ф-я замены значения всего массива полей ((value: D) => void)
 * @return object.removeValue - ф-я удаления поля в определённой позиции или удаление всех полей, если индекс не указан ((index?: number | number[]) => void)
 *
 */

export const useRhfFields = <D>(name: string) => {
  const { fields, append, replace, remove } = useFieldArray({ name });

  const values = fields as D[] | [];

  const addValue = append;

  const replaceValue = replace;

  const removeValue = remove;

  return { values, addValue, replaceValue, removeValue };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ ФОРМЫ ДЛЯ ПОЛУЧЕНИЯ ИХ В ПОЛЕ
 *
 * -
 *
 * @return object.trigger - принудительный запуск проверки формы ((name?: string | string[]) => Promise<boolean>)
 * @return object.isValid - значение, указывающее на то, есть ли в форме ошибки (boolean)
 * @return object.handleSubmit - ф-я отправки формы, при isValid === true
 * @return object.submitCount - количество отправок формы (number)
 * @return object.isSubmitting - указывает на то, отправляется ли форма в данный момент (boolean)
 *
 */

export const useRhfFormInField = () => {
  const { formState, trigger, handleSubmit } = useFormContext();

  const { isValid, submitCount, isSubmitting } = formState;

  // ------------------------------ логирование на время разработки

  // console.log("--------------------------------------------------");
  // console.log(`isValid-${isValid}`);

  return { trigger, isValid, handleSubmit, submitCount, isSubmitting };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * МЕТОДЫ МАССИВА ПОЛЕЙ ФОРМЫ
 *
 * -
 *
 * @param D - типы данных полей формы
 * @param mode - 	стратегия проверки поведения перед отправкой ("onBlur" | "onChange" | "onSubmit")
 * @param reValidateMode - стратегия проверки поведения после отправки ("onBlur" | "onChange" | "onSubmit" | "onTouched" | "all")
 * @param defaultValues - значения по умолчанию для формы (DefaultValues<D>)
 * @param resolver - объект валидации, обёрнутый в интегратор (Resolver<D, any>)
 * @param context - контекстный объект, предоставляемый для проверки схемы (any)
 * @param shouldFocusError - включить или отключить встроенное управление фокусом ("firstError" | "all")
 * @param shouldUnregister - включать и отключать отмену регистрации ввода после размонтирования (boolean)
 * @param shouldUseNativeValidation - используйте встроенный в браузер API для ограничения формы (boolean)
 * @param criteriaMode - отобразите все ошибки проверки или по одной за раз (CriteriaMode)
 * @param delayError - задержка мгновенного появления ошибки (number)
 *
 * @return object.rhfMethods
 * @return object.values - значения всех полей формы (() => D)
 * @return object.errors - значения всех ошибок формы (FieldErrors<D>)
 * @return object.setFocus - установка фокуса на конкретном поле ((name: string, options: { shouldSelect: boolean }) => void)
 * @return object.touched - объект, содержащий все поля, с которыми взаимодействовал пользователь
 * @return object.isDirty - значение, указывающее на то, было ли изменено хотя бы одно поле (boolean)
 * @return object.isValid - значение, указывающее на то, есть ли в форме ошибки (boolean)
 * @return object.setValues - ф-я для динамической установки значений полей ((newValues: NV) => void)
 * @return object.handleSubmit - ф-я отправки формы, при isValid === true
 * @return object.submitCount - количество отправок формы (number)
 *
 */

export const useRhfOutsideOfForm = <D>(args: UseFormProps<D>) => {
  // ------------------------------ все

  const rhfMethods = useForm<D>(args);

  // ------------------------------ значения формы

  const { getValues } = rhfMethods;
  const values = getValues();

  // ------------------------------ ошибки формы

  const { formState } = rhfMethods;
  const { errors } = formState;

  // ------------------------------ установка фокуса

  const { setFocus, trigger } = rhfMethods;

  // ------------------------------ тронуто ли хотя бы одно поле в форме

  const { touchedFields: touched } = formState;

  // ------------------------------ была ли изменена форма

  const { isDirty } = formState;

  // ------------------------------ валидна ли форма

  const { isValid } = formState;

  // ------------------------------

  const { setValue } = rhfMethods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValues = <NV extends Record<string, any>>(newValues: NV) => {
    Object.keys(newValues).forEach((key) => {
      const typedKey = key as keyof NV;
      setValue(typedKey as Path<D>, newValues[typedKey]);
    });
  };

  // ------------------------------ функция отправки формы

  const { handleSubmit } = rhfMethods;

  // ------------------------------

  const { submitCount } = formState;

  // ------------------------------ логирование на время разработки

  // console.log("--------------------------------------------------");
  // console.log(`isValid-${isValid}`);
  // console.log("values:", values);
  // console.log("errors:", errors);

  return {
    rhfMethods,
    values,
    errors,
    setFocus,
    touched,
    isDirty,
    isValid,
    setValues,
    handleSubmit,
    submitCount,
    trigger,
  };
};
