import { useAtom } from "jotai";
import React from "react";
import { FormProvider } from "react-hook-form";
import { filtersFormState } from "../../atoms";
import { FormContainer } from "../../styles";
import { useRhfOutsideOfForm } from "../../utils/useRHF";
import { FormButtons } from "../Form";
import { MethodsType } from "../Page";
import { RightModal, RightModalActions, RightModalBody } from "../RightModal";

export const Filters = <DATUM, OBJ, FLDS, VOBJ, FLTRS>(
  props: PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>
) => {
  const { children, methods } = props;

  const { api, queryKey } = methods;
  const { filtersFormName = "filtersForm", filtersFormTitle, fieldsFilters, setFilters } = methods;
  const { absoluteInitialValues, initialValuesFilters, filtersValidationSchema } = methods;

  const [filtersFormIsOpen, setFiltersFormIsOpen] = useAtom(filtersFormState);

  const handleClose = () => setFiltersFormIsOpen(false);

  const { rhfMethods, values, setValues, handleSubmit } = useRhfOutsideOfForm({
    mode: "all",
    defaultValues: initialValuesFilters,
    resolver: filtersValidationSchema,
  });

  if (!api || !queryKey) return null;

  return (
    <RightModal
      title={filtersFormTitle ?? "Фильтры"}
      isOpen={filtersFormIsOpen}
      onClose={handleClose}
      size="small"
    >
      <FormProvider {...rhfMethods}>
        {children ? (
          children
        ) : (
          <RightModalBody>
            <FormContainer
              id={filtersFormName}
              onSubmit={handleSubmit(async (values) => {
                if (!setFilters) return;

                setFilters(values as FLTRS);

                handleClose();
              })}
            >
              {typeof fieldsFilters === "function" ? fieldsFilters(values as FLTRS) : fieldsFilters}
            </FormContainer>
          </RightModalBody>
        )}

        <RightModalActions>
          <FormButtons
            formName={filtersFormName}
            onClose={handleClose}
            saveOrAdd="clear"
            disabled={false}
            onClear={() => setValues(absoluteInitialValues ?? {})}
          />
        </RightModalActions>
      </FormProvider>
    </RightModal>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS> = {
  methods: MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS>;
  children?: JSX.Element;
};
