import React, { FC, useEffect } from "react";
import { useRhfField } from "../../../utils/useRHF";
import { PlusMinusButton } from "../../Buttons";
import * as elements from "./NumberInput.styles";

export const NumberInput: FC<PropsType> = (props) => {
  const { comment, disabled = false, maxDisabled = false } = props;
  const { name, minValue, maxValue, description } = props;

  const { Comment, Container, Description, DescriptionText, Error, Input, Quantity } = elements;

  const { value, setValue } = useRhfField<number>(name);

  const hasErrorMaxValue = !disabled && maxValue && value >= maxValue;
  const hasErrorMinValue = !disabled && minValue && value < minValue;

  useEffect(() => {
    disabled ? setValue(0) : setValue(value);
  }, [disabled]);

  return (
    <Container disabled={disabled}>
      <Input>
        <PlusMinusButton
          type="minus"
          onClick={
            value === 0 || (!!minValue && value <= minValue)
              ? undefined
              : value === 1
              ? () => setValue(0)
              : maxValue && value > maxValue
              ? () => setValue(maxValue)
              : () => setValue(value - 1)
          }
          disabled={disabled || value === 0 || value === minValue}
        />

        <Quantity
          type="number"
          value={disabled || value === 0 ? "" : value}
          onChange={(evt) => {
            setValue(Number(evt.currentTarget.value));
          }}
          onKeyPress={(evt) => {
            if (evt?.key === "-" || evt?.key === "+" || evt?.key === "e") {
              evt.preventDefault();
            }
          }}
          hasError={
            +(
              !!value &&
              ((!disabled && !!minValue && value < minValue) || (!!maxValue && value > maxValue))
            )
          }
          disabled={disabled}
        />

        <PlusMinusButton
          type="plus"
          onClick={
            maxValue && value >= maxValue
              ? undefined
              : value === 0
              ? () => setValue(1)
              : () => setValue(value + 1)
          }
          disabled={disabled || value === maxValue || maxDisabled}
        />
      </Input>

      {description ? (
        <Description>
          <DescriptionText>{description}</DescriptionText>

          {comment && !hasErrorMaxValue && !hasErrorMinValue ? (
            <Comment>{`Максимум ${comment}`}</Comment>
          ) : null}

          {hasErrorMaxValue ? (
            <Error>
              {description} не должно превышать {maxValue}
            </Error>
          ) : null}
          {hasErrorMinValue ? (
            <Error>
              {description} не должно быть меньше {minValue}
            </Error>
          ) : null}
        </Description>
      ) : null}
    </Container>
  );
};

type PropsType = {
  name: string;
  minValue?: number;
  maxValue?: number;
  description?: string;
  comment?: number;
  disabled?: boolean;
  maxDisabled?: boolean;
};
