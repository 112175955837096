import React, { FC } from "react";
import { useRhfField } from "../../utils/useRHF";
import { Slider, SliderContainer, SubTitle, Title } from "./SliderInput.styles";

export const SliderInput: FC<PropsType> = (props) => {
  const { title, track = "normal", subText, name, max = 10 } = props;

  const { value, setValue } = useRhfField<number>(name);

  return (
    <SliderContainer>
      <Title>{title}</Title>
      <SubTitle>{subText(value)}</SubTitle>
      <Slider
        track={track}
        aria-labelledby={name}
        value={value}
        valueLabelFormat={(value) => (track === "inverted" ? max - value : value)}
        max={max}
        onChange={(_evt, changeValue) => setValue(changeValue as number)}
      />
    </SliderContainer>
  );
};

type PropsType = {
  name: string;
  title: string;
  track?: "normal" | "inverted";
  subText: (value: number) => string;
  max?: number;
};
