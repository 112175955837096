import * as DOMPurify from "dompurify";
import React, { FC } from "react";
import "react-quill/dist/quill.snow.css";
import { useRhfField } from "../../../utils/useRHF";
import { Error } from "../NumberInput/NumberInput.styles";
import { Quill } from "./StyledTextInput.styles";

export const StyledTextInput: FC<PropsType> = (props) => {
  const { name, placeholder } = props;

  const { value, error, onChange } = useRhfField<string>(name);

  const modules = {
    toolbar: [[{ header: [1, 2, false] }], ["bold", "italic"], ["clean"], [{ align: [] }]],
  };

  return (
    <>
      <Quill
        // todo: для этой библиотеки отображается предупреждение: - задача 29872
        //       Listener added for a 'DOMNodeInserted' mutation event. This event type is deprecated, and will be removed from this browser very soon.
        //       Usage of this event listener will cause performance issues today, and represents a large risk of future site breakage.
        //       Consider using MutationObserver instead. See https://chromestatus.com/feature/5083947249172480 for more information.
        error={!!error}
        modules={modules}
        theme="snow"
        value={DOMPurify.sanitize(value)}
        onChange={onChange}
        placeholder={placeholder}
      />

      {error && <Error>{error}</Error>}
    </>
  );
};

type PropsType = {
  name: string;
  placeholder: string;
};
